export const Projects = [
  {
    name: "Mr. Rajesh Yadav",
    add: "Pardesipura,Indore",
    area: "3152",
    floor: "G + 2",
    cost: "54.5 Laks",
    url: "/assets/rajesh4.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/rajesh1.png",
      "/assets/rajesh2.png",
      "/assets/rajesh3.png"
    ]
  },
  {
    name: "Mr. Vinay Pingle Residence",
    add: "Andanva,Prayagraj",
    area: "3023",
    floor: "G + 1",
    cost: "52.3 Laks",
    url: "/assets/vinay1.png",
    months: "7.5",
    rating: "4.8",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/vinay1.png",
      "/assets/vinay2.png",
      "/assets/vinay3.png"
    ]
  },
  {
    name: "Mr. Priyansh Joshi's",
    add: "Naini,Prayagraj",
    area: "2854",
    floor: "G + 1",
    cost: "65.4 Lakhs",
    url: "/assets/priyansh4.png",
    months: "7.5",
    rating: "4.6",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/priyansh1.png",
      "/assets/priyansh2.png",
      "/assets/priyansh3.png"
    ]
  },
  {
    name: "Mr. Rajesh Sahu",
    add: "Lalghati,Bhopal",
    area: "3794",
    floor: "G + 2",
    cost: "54.28 Lakhs",
    url: "/assets/aashu4.png",
    months: "11",
    rating: "4.6",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "https://www.youtube.com/embed/lg7bE3lPVG0",
    photoGallery: [
      "/assets/aashu5.png",
      "/assets/aashu2.png",
      "/assets/aashu3.png"
    ]
  },
  {
    name: "Mr. Wadhvani Residence",
    add: "Bengali Square,Indore",
    area: "1750",
    floor: "G + 1",
    cost: "28.9 Lakhs",
    url: "/assets/wadhwani4.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/wadhwani1.png",
      "/assets/wadhwani2.png",
      "/assets/wadhwani3.png"
    ]
  },
  {
    name: "Akshat Apartment",
    add: "Ashoka Garden,Bhopal",
    area: "12160",
    floor: "G + 4",
    cost: "158.42 Lakhs",
    url: "/assets/akshat1.png",
    months: "14",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/akshat2.png",
      "/assets/akshat3.png"
    ]
  },
  {
    name: "Mr. Mahesh Sahu",
    add: "Lalghati,Bhopal",
    area: "3550",
    floor: "G + 2",
    cost: "57.6 Lakhs",
    url: "/assets/mahesh1.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
  },
  {
    name: "Mr. Prashant Villa",
    add: "RK Puram,Prayagraj",
    area: "2850",
    floor: "G + 1",
    cost: "48.6 Lakhs",
    url: "/assets/prashant1.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/prashant2.png",
      "/assets/prashant3.png",
      "/assets/prashant4.png"
    ]
  },
  {
    name: "Mrs Geeta Residence",
    add: "Lalghati,Bhopal",
    area: "3554",
    floor: "G + 2",
    cost: "54.10 Lakhs",
    url: "/assets/geeta4.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/geeta1.png",
    ]
  },
  {
    name: "Mr. Akshat Kanasal",
    add: "Bhawarkua,Indore",
    area: "2505",
    floor: "G + 1",
    cost: "48.6 Lakhs",
    url: "/assets/a1.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `    `,
    about: ``,
    videourl: "",
    photoGallery: [
      "/assets/a2.png",
      "/assets/a3.png"
    ]
  },
  {
    name: "Mr & Mrs Sahu Residence",
    add: "Nayapura,Bhopal",
    area: "2700",
    floor: "G + 2",
    cost: "40.10 Lakhs",
    url: "/assets/sahu1.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: ``,
    about: ``,
    videourl: "",
  },
  {
    name: "Grace Garden Housing",
    add: "BHEL,Bhopal",
    area: "1200",
    floor: "G + 1",
    cost: "66.20 Lakhs",
    url: "/assets/gg1.png",
    months: "9.5",
    rating: "4.5",
    contractorName: "KONNBOT Team",
    constructorData: `   `,
    about: ``,
    videourl: "https://www.youtube.com/embed/k7vAqPTVKjY",
  },
];
